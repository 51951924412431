.component-home {
  .w-100 {
    width: 100%;
  }
  .w-120 {
    width: 120%;
  }
  .w-70 {
    width: 70%;
  }
  .w-50 {
    width: 50%;
  }

  #fb iframe {
    @media (max-width: 370px) and (min-width: 200px) {
      padding: 10px 25px 0px 5px;
      width: 800px;
      height: 500px;
    }
  }

  // #sidebar{
  //   @media (max-width: 1016px) and (min-width: 280px) {
  //     padding: 0 15px 0px 5px;
  //   }
  // }

  .home-section {
    .home-slider {
      // @media (max-width: 785px){
      //   padding: 0 20px;
      // }
      .qatar-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .tuneInContainer {
        width: 93%;
        background-color: #ed1c23;
        // height: 30%;
        margin: 15px 0px 10px 25px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media (max-width: 432px) and (min-width: 200px) {
          width: 90%;
          margin: 20px;
          height: 97px;
        }

        .tuneInContainerText {
          color: white;
          padding-top: 5%;
          padding-left: 3%;
          // float: left;
          cursor: default;
          @media (max-width: 483px) and (min-width: 200px) {
            font-size: medium;
          }
        }
      }
      .slick-prev {
        left: 10px;
        z-index: 2;
        margin-top: -45px;
      }
      .slick-next {
        right: 10px;
        z-index: 2;
        margin-top: -35px;
      }
      .single-slider {
        position: relative;
        cursor: pointer;
        .slider-text {
          position: absolute;
          width: 100%;
          background-color: rgba(35, 35, 49, 0.9);
          color: #fff;
          padding: 10px 20px;
          bottom: 0;
          z-index: 9;
          h2 {
            font-size: 24px;
            @media (max-width: 768) {
              font-size: 16px;
            }
          }
        }
      }
      .sponsorLogo {
        // background-color: white;
        margin-top: 2%;
        img {
          width: 120px;

          @media (max-width: 765px) and (min-width: 500px) {
            width: 90px;
          }
          @media (max-width: 501px) and (min-width: 300px) {
            width: 80px;
          }
        }
      }
    }
    .newsDes {
      font-weight: 100;
      font-size: 13px;
      margin-top: 10px;
      color: #888787;
      height: 75px;
      overflow: hidden;
      text-overflow: ellipsis; 
      line-height: normal;
    }
    // strong{
    //   line-height: normal;
    // }
    .max-width {
      max-width: 1280px;
      @media (min-width: 1025px) and (max-width: 1368px) {
        max-width: unset;
        padding: 0 60px;
      }
    }
    .image-section {
      height: 50vh;
      background-image: url("../../static/images/homeImg.png");
      background-position: center;
      background-size: cover;
      // background-repeat: no-repeat;
      // background-repeat: no-repeat;
      @media (min-width: 1024px) {
        // background-color: #b61505;
        background-image: url();
        height: auto;
      }
      @media (max-width: 835px) and (min-width: 768px) {
        height: 40vh;
      }
      .text-div {
        bottom: 25px;
        left: 0;
        //text-shadow: 2px 2px 6px #000;
        @media (min-width: 1024px) {
          bottom: 20%;
          text-align: left;
          left: 220px;
        }
      }
      .heading-text {
        line-height: 1.76;
        letter-spacing: -0.4px;
      }
      .heading-subtext {
        font-size: 32px;
        @media (min-width: 1024px) {
          font-size: 48px;
          line-height: 0.91;
          letter-spacing: -3.63px;
        }
      }
      .mike-desktop {
        width: 25%;
      }
      .qatar-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .qatar-banner-desktop {
        height: 320px;
        width: 100%;
        object-fit: cover;
      }
      .uae-banner-desktop {
        //height: 380px;
        width: 100%;
      }
    }
    .stations-slider {
      @media (max-width: 1024px) {
        // flex-wrap: wrap;
        .component-card {
          width: 100%;
          margin-right: 12px;
        }
      }
      @media (max-width: 767px) {
        .component-card {
          width: 100%;
        }
      }
      @media (max-width: 576px) {
        .component-card {
          width: 100%;
        }
      }
      @media (min-width: 1025px) {
        overflow: hidden;
        flex-wrap: wrap;
        // padding: 40 60px;
        // max-width: 65%;
        // justify-content: space-between;
      }
      .component-card {
        @media (min-width: 1369px) {
          width: 100%;
          margin-right: 12px;
          // margin-bottom: 12px;
        }
        @media (max-width: 1368px) and (min-width: 1025px) {
          width: 100%;
          margin-right: 12px;
          //   margin-bottom: 12px;
        }
      }
    }
    // .rjs-slider {
    //     @media (max-width: 835px) {
    //         .component-card {
    //             width: 40% !important;
    //             // div {
    //             //     width: 100% !important;
    //             // }
    //         }
    //     }
    //     @media (max-width: 767px) {
    //         .component-card {
    //             width: 50%;
    //         }
    //     }
    //     @media (max-width: 576px) {
    //         .component-card {
    //             width: 80%;
    //         }
    //     }
    // }
    .rjs-carousel {
      &.ie-carousel {
        .slick-track {
          width: 4500px !important;
        }
        .slick-slide {
          width: 120px !important;
          margin-right: 90px !important;
        }
      }
    }
    .events-carousel {
      .slide {
        flex: 0 0 35% !important;
      }
    }
    .reviews-carousel,
    .logos-carousel {
      .control-arrow {
        bottom: 65px;
      }
    }
    .carousel.carousel-slider {
      position: unset;
      .slide {
        background: transparent;
        min-width: unset;
        flex: 0 0 25%;
      }
      .control-arrow {
        opacity: 1;
        right: 35px;
      }
      .control-next.control-arrow:before {
        content: url("../../static/images/awards-arrow.svg") !important;
        border-left: unset;
      }
      .control-prev.control-arrow:before {
        content: url("../../static/images/awards-arrow1.svg") !important;
        right: -35px !important;
        border-right: unset;
      }
    }
    .rj-card,
    .station-card {
      flex: 0 0 55%;
      @media (max-width: 835px) and (min-width: 576px) {
        width: 33%;
        margin-right: 12px;
        div {
          width: 100% !important;
        }
      }
      @media (max-width: 576px) {
        width: 55%;
        margin-right: 12px;
        div {
          width: 100% !important;
        }
      }
    }
    .events-card {
      flex: 0 0 75%;
    }
  }
  .mobile-tray {
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    scroll-behavior: smooth;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    // padding-right: 20px;
    transition: all 0.3s ease 0s;
    display: block;
    .component-card {
      &:last-child {
        margin-right: 0;
        div {
          margin-right: 0;
        }
      }
    }
  }
  .top20-section {
    background-color: #08080a;
    @media (min-width: 1024px) {
      // max-width: 350px;
      // max-width: 450px;
      width: 350px;
      top: 340px;
      right: calc((100vw - 1280px) / 2);
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      overflow: auto;
      background-color: #151214;
      z-index: 10;
    }
    @media (min-width: 1113px) and (max-width: 1368px) {
      right: 60px;
    }
    @media (max-width: 1112px) and (min-width: 1025px) {
      right: 20px;
      width: 300px;
    }
    @media (max-width: 1025px) and (min-width: 836px) {
      right: 20px;
      width: 250px;
    }
    &.top20-fixed {
      position: fixed;
      // max-width: 500px;
      // width: 25vw;
      top: 200px;
      // right: 80px;
      margin-bottom: 50px;
      // bottom: 50px;
    }
    .top20-slider {
      padding: 0 65px !important;
    }
    .date-pill {
      border-radius: 12px;
      background-color: #2d2d30;
      margin: 0 auto;
      margin-bottom: 30px;
      max-width: 40%;
      @media (min-width: 1113px) {
        position: relative;
        bottom: calc(-50% - 200px);
      }
      @media (max-width: 1112px) and (min-width: 836px) {
        position: relative;
        bottom: -270px;
        max-width: 60%;
      }
      @media (max-width: 835px) and (min-width: 768px) {
        position: relative;
        bottom: 0;
        max-width: 150px;
      }
    }
    .countdown-div {
      padding: 12px 45px;
      background-color: #2d2d30;
      max-width: 75%;
      margin: 0 auto;
      @media (max-width: 835px) and (min-width: 768px) {
        max-width: 280px;
      }
    }
    .carousel.carousel-slider {
      .slide {
        display: flex;
        justify-content: unset;
        width: 80%;
      }
    }
    .top-section {
      background-image: linear-gradient(to bottom, #fff370, #ffe808);
      height: 145px;
      @media (max-width: 1024px) {
        height: 100px;
      }
      .top-20 {
        bottom: -48%;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: 1024px) {
          bottom: -50px;
          max-height: 120px;
          width: auto;
          // left: calc(50% - 86px);
        }
      }
      .authentic-text.mirchi-text {
        bottom: calc(-50% - 40px);
        @media (max-width: 1024px) {
          bottom: -100px;
        }
      }
    }
    .top-20-container {
      @media (min-width: 1113px) {
        margin-top: 180px;
      }
      @media (max-width: 1112px) {
        margin-top: 140px;
        padding-top: 20px;
      }
      background-color: #151214;
    }
    .active-songs {
      border: solid 1px #29292e;
      @media (max-width: 1024px) {
        padding: 12px 8px;
      }
    }
    .full-list {
      background-color: #e91e25;
      @media (max-width: 1024px) {
        padding: 12px 24px;
      }
    }
  }
  .mirchi-murga-section {
    background: url("../../static/images/mirchi-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    @media (min-width: 1024px) {
      background: url("../../static/images/mirchi-bg-desk.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      background-position: center;
    }
    .rj-picture {
      top: -45px;
      left: calc(50% - 120px);
    }
  }
}

.slick-prev {
  height: 20px;
  width: 10px;
  top: 50%;
  transform: rotateY(180deg);
  background-image: url("../../static/images/arrow.svg") !important;
  background-repeat: no-repeat;
  &::before {
    display: none !important;
  }
}

.slick-next {
  height: 20px;
  width: 10px;
  top: 50%;
  background-repeat: no-repeat;
  background-image: url("../../static/images/arrow.svg") !important;
  &::before {
    display: none !important;
  }
}

.slick-disabled {
  display: none !important;
}

.home-outer-container {
  //max-width: 1280px;
  padding-left: calc((100vw - 1280px) / 2 + 15px);
  @media (min-width: 1025px) and (max-width: 1368px) {
    padding-left: 55px;
  }
}

.home-container {
  @media (max-width: 1024px) {
    max-width: calc(100% - 250px);
    padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) {
    max-width: calc(100% - 350px - (100vw - 1280px) / 2);
    padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) and (max-width: 1368px) {
    max-width: calc(100% - 400px - (100vw - 1280px) / 2);
    padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (max-width: 835px) {
    max-width: unset;
    padding-right: 0;
  }
}
.home-container2 {
  @media (max-width: 1024px) {
    max-width: calc(100%);
    padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) {
    max-width: calc(100% - (100vw - 1280px) / 2);
    padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) and (max-width: 1368px) {
    max-width: calc(100% - (100vw - 1280px) / 2);
    padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (max-width: 835px) {
    max-width: unset;
    padding-right: 0;
  }
}
.modal-my {
  z-index: 10000;
}
.videos-slider {
  .slick-next,
  .slick-prev {
    top: 30% !important;
  }
  &.online-stations-slider {
    .slick-next,
    .slick-prev {
      top: 48% !important;
    }
  }
}
.online-station-image {
  width: 195px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
  padding: 5px;
  border-radius: 15px;
}
.pic-img {
  height: 150px;
  border-radius: 15px;
  object-fit: cover;
  padding: 5px;
  width: 195px !important;
}

.reviews-slider,
.logo-slider {
  .slick-next,
  .slick-prev {
    top: 40% !important;
  }
  .max-width {
    max-width: 1280px;
  }
}
.reviews-slider-mobile {
  @media (max-width: 1024px) {
    display: flex !important;
  }
}
.logos-carousel {
  margin-bottom: 80px;
  &.online-stations-carousel {
    margin-bottom: 10px;
  }
}
.logos-section {
  max-width: 1260px;
  .logo-slider {
    &.mobile-tray {
      margin-bottom: 20px;
      .logo-container {
        width: 30%;
        display: inline-block;
        &:not(:last-child) {
          margin-right: 30px;
        }
        @media (max-width: 1024px) and (min-width: 768px) {
          width: 20%;
        }
      }
    }
    .logo-container {
      &:focus {
        border: unset;
        outline: none;
      }
    }
    .sponsors-logo {
      width: auto;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 80px;
      max-width: unset !important;
      background-image: url("../../static/images/placeholder-logo.jpg");
    }
    .slick-track {
      width: 3000px !important;
      display: flex;
      align-items: center;
    }
    .slick-slide {
      width: 160px !important;
      margin-right: 45px !important;
      @media (max-width: 1025px) {
        width: 140px !important;
      }
    }
    .slick-list {
      width: 100%;
      @media (max-width: 1025px) {
        width: 95%;
      }
    }
    .slick-next {
      right: -25px;
      top: 48% !important;
      @media (max-width: 1025px) {
        right: 10px;
      }
    }
    .slick-prev {
      right: -18px;
    }
    .slick-prev {
      top: 35% !important;
    }
  }
}
.fixedContainer {
  font-weight: 100;
  padding-left: 5px;
  font-size: 13px;
  margin-top: 10px;
  color: #888787;
  text-align: justify;
  line-break: anywhere;
  position: relative;
  width: 190px;
  // height: 100px;
  line-height: normal;
  // left: 50%;
}
