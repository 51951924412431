//@import "./fonts.scss";
.hidden-desktop {
  @media (min-width: 1024px) {
    display: none !important;
  }
}

.hidden-tablet {
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.text-34 {
  font-size: 34px;
}

.width-fit-content {
  width: fit-content;
}

.bottom-shadow {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, -2), #000);
  left: 0;
  bottom: 0;
}

.text-11 {
  font-size: 11px;
  line-height: 18px;
}

.light-shadow {
  box-shadow: 0 9px 14px 0 rgba(11, 28, 46, 0.07);
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "CircularStd", serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.04rem;
  line-height: 1;
  -webkit-overflow-scrolling: touch;
}

#root {
  height: 100%;
}

a {
  text-decoration: none;
  outline: none;
}

// -----For scrollbar ------
// ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent;
//     /* make scrollbar transparent */
// }

.live-container {
  box-shadow: 0 3px 7px 0 rgba(232, 36, 43, 0.3);
  background-image: linear-gradient(to bottom, #ff5258, #e1171e);
}

.mobile-tray {
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  scroll-behavior: smooth;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 20px;
  transition: all 0.3s ease 0s;
  display: block;
  .component-card {
    &:last-child {
      margin-right: 0;
      div {
        margin-right: 0;
      }
    }
  }
}

.app-router {
  @media (min-width: 1024px) {
    padding-top: 70px;
  }
  // @media (max-width: 835px) {
  //     padding-top: 70px;
  // }
}

// .app-router.globalPlayer {
//     padding-bottom: 48px;
// }
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

video::-webkit-media-controls {
  display: none !important;
}

video {
  &::-webkit-media-controls {
    display: none !important;
  }
  &::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
  }
}

.inquiry-modal,
.countDown-modal {
  position: fixed;
  left: unset;
  width: auto;
  right: calc((100vw - 1280px) / 2);
  opacity: 0;
  visibility: hidden;
  z-index: -10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #e1171e;
  @media (max-width: 1112px) {
    right: 20px;
  }
  &.active {
    width: 500px;
    opacity: 1;
    visibility: visible;
    z-index: 1000;
    top: 80px;
    left: unset;
    border-radius: 6px;
    &.event {
      width: 100%;
      top: 0;
      bottom: unset;
      background: rgba(#000, 0.8);
      left: 0;
      right: 0;
      height: 100%;
      align-items: flex-start;
      padding-top: 90px;
      .inner-content {
        margin: 0 auto;
      }
    }
  }
  .for-inquery {
    width: 100%;
    position: relative;
    .close-icon {
      display: block;
      cursor: pointer;
    }
  }
}
.countDown-modal, .subItems-holder {
  padding: 12px;
  background-color: #fff;
  width: 300px !important;
  display: block;
  box-shadow: 0 1px 1px 1px #ccc;
  a {
    &:hover {
      color: red;
    }
  }
  .close-icon {
    right: 5px;
    top: 5px;
  }
  .dropArrow {
    background: url(../images/triangle-arrow.png);
    height: 12px;
    left: 50%;
    background-repeat: no-repeat;
    margin-left: -8px;
    position: absolute;
    top: -11px;
    width: 25px;
  }
  .countdown-item {
    &::before {
      content: "";
      height: 8px;
      margin-right: 10px;
      width: 8px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
    }
    margin-bottom: 12px;
  }
}
.subItems-holder{
    height: auto;
    max-height: 400px;
    overflow: scroll;
    position: fixed;
    right: calc(((100vw - 1280px) / 2 ) + 300px);
}

.install-message-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(#000, 0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    height: 70vw;
    width: 70vw;
    @media (max-width: 320px) {
      height: 75vw;
      width: 78vw;
    }
    background: #fff;
    border-radius: 10px;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .close-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .img-container {
      width: 100%;

      .logo {
        max-width: 120px;
        height: auto;
        margin: 0 auto;
      }
    }

    .text-support {
      font-size: 14px;
      color: #696969;
    }

    .tap-share-text {
      font-size: 12px;
      position: absolute;
      bottom: 20px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #696969;

      .share {
        max-width: 12px;
        height: auto;
        margin: 0 4px;
      }
    }
  }
}
