.component-global-player {
    box-shadow: 0 2px 24px 0 rgba(41, 29, 37, 0.24);
    background: #f3f5f7;
    @media (min-width: 768px) {
        width: 350px;
        left: calc((100vw - 1280px) / 2 + 20px);
        bottom: 50px;
        border-radius: 100px;
        max-width: 100%;
        &.radio {
            width: auto;
            min-width: 200px;
            height: auto;
        }
    }
    @media (max-width: 1368px) and (min-width: 1025px) {
        left: 75px;
    }
    @media (max-width: 1024px) {
        left: 32px;
    }
    @media (max-width: 835px) {
        left: 0;
    }
    .inner-global-player {
        max-width: 1280px;
        @media (min-width: 1025px) and (max-width: 1368px) {
            max-width: unset;
        }
    }
    .overlay {
        background: #fff;
        width: 100%;
        opacity: 0.1;
        height: 48px;
        left: 0;
        right: 0;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
    .cross-div {
        top: 5px;
        right: 5px;
    }
    .react-h5-audio-player {
        &.radio {
            display: none !important;
        }
        >div.flex {
            box-shadow: unset !important;
            background: #f3f5f7 !important;
            // padding: 20px 0 0 !important;
            height: 20px !important;
            border-radius: 25px !important;
            padding: 0 !important;
        }
        .toggle-play-wrapper {
            height: 100%;
            flex: 1 0 auto !important;
            display: flex;
            align-items: center;
            max-width: 50px;
            min-width: 50px;
            width: 50px;
            display: none !important;
            .toggle-play-button {
                display: flex;
                align-items: center;
                justify-content: center;
                // Remember to use !important to overwrite inline styles.
                // background-color: #ce2f31 !important;
                width: 30px !important;
                height: 30px !important;
                background: transparent !important;
                margin-left: -15px !important;
                padding-top: 0 !important;
                left: 32% !important;
                i.play-icon {
                    background: url('../../static/images/play-button.svg') no-repeat center;
                    border-top: 0 !important;
                    border-bottom: 0 !important;
                    border-left: 0 !important;
                    position: relative !important;
                    left: 0 !important;
                    background-size: 100% 100%;
                    width: 24px !important;
                    height: 24px !important;
                    top: 0 !important;
                }
                i.pause-icon {
                    background: url('../../static/images/pause-circle.svg') no-repeat center;
                    box-shadow: unset !important;
                    width: 25px !important;
                    height: 25px !important;
                    background-size: 100% 100%;
                    left: 0 !important;
                    //width: 15px !important;
                    //height: 15px !important;
                    border-top: 0 !important;
                    border-bottom: 0 !important;
                    border-left: 0 !important;
                    background-color: transparent !important;
                }
            }
        }
        .progress-bar-wrapper {
            padding: 0 80px 0 0 !important;
            @media (max-width: 767px) {
                padding: 0 60px 0 0 !important;
            }
            justify-content: center !important;
            //display: none !important;
            >div:first-child {
                height: 2px !important;
                color: #d8dbe0 !important;
            }
            div.indicator {
                background: #ce2f31 !important;
                top: calc(50% - 5px) !important;
                height: 10px !important;
                width: 10px !important;
                opacity: 1 !important;
            }
            div.audio-info {
                color: #000;
                position: absolute;
                right: 0;
                top: 0;
                font-size: 14px;
                @media (max-width: 767px) {
                    font-size: 10px;
                    right: 5px;
                }
                .time {
                    position: relative;
                    right: 0;
                    top: 4px;
                    color: #858990;
                    font-size: 12px;
                    @media (max-width: 767px) {
                        font-size: 10px;
                    }
                }
                .volume-controls {
                    display: none;
                    .volumn {
                        border-bottom: 15px solid #ce2f31 !important;
                    }
                }
            }
        }
        &.loading {
            .toggle-play-wrapper {
                .toggle-play-button {
                    i.play-icon,
                    i.pause-icon {
                        background-image: url('../../static/images/player-loader.gif') !important;
                    }
                }
            }
        }
        &.radio,
        &.loading {
            .progress-bar-wrapper {
                visibility: hidden !important;
                padding: 0 20px 0 0 !important;
                div.audio-info {
                    .time {
                        display: none;
                    }
                }
            }
        }
    }
    .audio-title {
        // max-width: 300px;
        width: 100%;
        // @media (max-width: 767px) {
        //     max-width: 150px;
        // }
        &.radio {
            //min-width: 120px;
            max-width: unset;
        }
        .audio-title-span{
            max-width: 90%;
        }
    }
    .inner-container{
        width: 90%;
    }
    .audioTitle{
        width: 100%;
    }
    .close-icon-holder{
        right: 20px;
        top: 25%;
        z-index: 1;
        transform: translateY(-50%);
        .close-icon{
            width: 20px;
            height: auto;
        }
        @media (max-width: 767px){
            top: 22%;
        }
    }
}