body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.inactive{
  display: none !important;
}
.loader-div {
  background-color: rgba(0, 0, 0, 0.5);
  /* // margin-top: 85px; */
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;

}

.app-loader {
  width: auto;
  z-index: 1000;
  max-height: 50px;
}

