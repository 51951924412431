* {
  font-weight: lighter;
}
.wrapper {
  margin: 0px 15%;
  width: 60%;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width:1024px) and (min-width: 300px) {
    margin-top: 5%;
  }
  @media (max-width:735px) and (min-width: 300px) {
    margin: 10% 10%;
    width: 80%;
  }

}
// .contests {
  // margin: 0px 30px;
  // padding-top: 40px;
  // padding-left: 17%;
  // @media (max-width: 1024px) and (min-width: 836px) {
  //     padding: 40px 32px 40px 0;
  // }
  // @media screen and (min-width: 1024px) {
  //     max-width: 1280px;
  //     margin: 0 auto;
  // }
  // @media (min-width: 1025px) and (max-width: 1368px) {
  //     max-width: unset;
  //     padding: 0 40px;
  // }
  
// }

.contests-list {
  margin: 0px 0px;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
h2 {
  font-weight: bolder;
}
p {
  line-height: 25px;
}
.flex-items {
  height: 158px;
  width: 188px;
  border: 2px solid white;
  margin: 10px 10px;
  border-radius: 15px;
}

.first-title {
  font-size: 25px;
  background-color: #e21e26;
  color: white;
  padding: 15px;
}
.second-title {
  font-size: 25px;
  background-color: #e21e26;
  color: white;
  padding: 15px;
}
.third-title {
  font-size: 25px;
  background-color: #e21e26;
  color: white;
  padding: 15px;
}
.fourth-title {
  font-size: 25px;
  background-color: #e21e26;
  color: white;
  padding: 15px;
}
.fifth-title {
  font-size: 25px;
  background-color: #e21e26;
  color: white;
  padding: 15px;
}
.contest-banner {
  width: 100%;
  height: 300px;
}
.warning-text {
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  margin: 0px 0px;
}
// .sub-content {
//   font-weight: bold;
// }
.rules-list {
  line-height: 40px;
}
.last-images {
  text-align: center;
}
a {
  color: #1b3685;
  margin-left: 5px;
}
.mainSponsors{
  display: flex;
  flex-direction: row;
  margin-left: 30%;
  img{
    width: 20%;
    height: 20%;
    margin: 1% 2%;
  }
}
.otherSponsors{
  display: flex;
  flex-direction: row;
  // margin-left: 40px;
  img{
    // width: 160px;
    // height: 160px;
    width: 20%;
    height: 20%;
    margin: 1% 2%;
  }
  @media (max-width:1024px) and (min-width: 300px) {

  }
  @media (max-width:735px) and (min-width: 300px) {
 
  }
}
.sponsor{
  .sponsorLogo{
    margin: auto;
  }
}