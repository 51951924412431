.singleBlog2 {
  width: 100%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: row;
  @media (max-width:900px) {
  padding-bottom: 15%;
    
  }
  .blogImg2 {
    width: 250px;
    height: 225px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 10px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    @media (max-width:900px) {
      width: 250px;
      height: 200px;
    }
  }
  img{
    width: 300px;
    height: 225px;
    object-fit: cover;
    @media (max-width:900px) {
      width: 250px;
      height: 200px;
    }
  }
 
  .blog-about2 {
    width: 65%;
    // height: 30%;
    // padding: 0 3%;
    display: inline-block;
    vertical-align: middle;
    // width: calc(100% - 250px);
    padding: 2% 0 0 25px;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }

  @media (max-width: 600px) and (min-width: 200px) {
    flex-direction: column;
    .blog-about2 {
      padding: 4% 0 0 5%;
      width: 100%;
    }
    // .blogImg2 {
    //   width: 100%;
    // }
  }
}

.description2 {
  color: gray;
  font-weight: 500;
  font-size: 14px;
  padding-top: 10px;
  // padding-right: 25px;
  p{
    line-height: normal;
  }
}
.category2 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 11px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.05em;
  text-decoration: none;
  text-transform: uppercase;
  // letter-spacing: -1.15px;
  color: purple;
  padding-bottom: 10px;
}
.title2 {
  // font-family: "Roboto Condensed", sans-serif;
  font-family: "CircularStd", serif;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 1.2em;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: normal;
  color: black;
}
.title2:hover{
  color: purple;
}
