
.component-youtube-modal{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
  top: 0;
  left: 0;
  visibility: hidden;
  iframe {
    border-radius : 8px;
  }
  .close-icon {
    top: 20px;
    right: 20px;
  }
  &.active {
    visibility: visible;
  }

}
