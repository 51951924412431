.header-menu-wrap {
  background-color: rgba(9, 6, 4, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  height: 100%;
}

.header-menu-wrap.active {
  visibility: visible;
  opacity: 1;
  z-index: 8;
}

.component-header-menu {
  width: 303px;
  height: 100%;
  background-color: #fff;
  left: -100%;
  padding-top: 88px;
  transition: all 0.5s;
  overflow-y: auto;
  z-index: 8;
  position: fixed;
  top: 0;

  .header-menu {
    padding-bottom: 56px;
    padding-top: 10px;

    a {
      display: block;
      margin: 0 20px;
      font-size: 20px;
      line-height: 37px;
      position: relative;
      font-weight: 700;
      transition: all 0.5s;
      letter-spacing: -0.56px;

      &:after {
        position: absolute;
        left: -20px;
        width: 4px;
        height: 0%;
        content: "";
        background-color: #ed1c23;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.5s;
      }
    }

    a.active {
      color: #ed1c23;
      display: block;

      &:after {
        height: 100%;
      }
    }
  }
}

.component-header-menu.active {
  left: 0;
}

.sidebar-footer {
  background: #ed1c23;
  padding: 24px 20px;

  .social-links {
    margin-bottom: 20px;

    a {
      width: 30px;
      height: 30px;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.policy-links {
  margin-bottom: 5px;

  a {
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    position: relative;
    margin-right: 15px;
    line-height: 1.64;
    letter-spacing: -0.1px;

    &:after {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #fff;
      content: "";
      position: absolute;
      left: -10px;
      top: 6px;
    }

    &:first-child {
      &:after {
        display: none;
      }
    }
  }
}

.copyright-info {
  span {
    font-size: 11px;
    letter-spacing: -0.1px;
    color: #fff;
    display: block;
    line-height: 1.64;
  }
}