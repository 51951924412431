.movie-details {
    color: #c4c4cd;
}

.ranking-container {
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    // @media (max-width: 1024px) {
    //     // left: 10px;
    //     transform: translateX(0%);
    // }
}

.movie-card-holder {
    cursor: pointer;
    .img-container {
        //cursor: pointer;
        .play {
            position: absolute;
            top: calc(50% - 15px);
            left: calc(50% - 15px);
            width: 40px;
            height: auto;

        }
        //margin-bottom: 40px;
        //
        //&.top20Listing {
        //    width: 60vw;
        //    max-width: 250px;
        //}
        //
        //&.sideBar {
        //    margin-bottom: 0;
        //}
    }
}

.home-movie-card {
    bottom: -22px;
}

.scale-img {
    transform: scale(0.5);
}

.top20-card {
    top: 0;
    right: -5px;
    @media (min-width: 1113px) {
        right: 5px;
    }
    @media (max-width: 1112px) {
        right: -10px;
    }
}

.top20-desktop {
    text-align: left !important;
    @media (max-width: 1024px) {
        font-size: 13px;
    }
}

.ranking-holder {
    background-image: linear-gradient(to top, #e0e0ea, #ffffff);
    padding: 20px 10px;
    @media (max-width: 1024px) {
        font-size: 32px;
    }
    .rank {
        width: 65%;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
        border: solid 2px #eaeaf1;
    }
    .positive-rank {
        background-color: #38b515;
    }
    .negative-rank {
        background-color: #f1373d;
    }
    .new {
        width: fit-content;
        background: #ee8b00
    }
}
