// .card-details {
//   left: 24px;
//   bottom: 20px;
//   white-space: normal;
//   .name{
//       padding: 20px 10px;
//   }
// }
.cardoverlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 20px;
  left: 0;
  height: 40%;
  width: 100%;
  .desc {
    color: white;
    padding: 5%;
    font-weight: 600;
    font-size: medium;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
.category{
  padding: 5px 0 0 5%;
}
  
}
