.header {
    @media (min-width: 1024px) {
        height:auto;
    }
    transition: all 0.1s ease;
    &.show-shadow {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    }
    &.no-shadow {
        box-shadow: unset;
    }
    .mirchi-logo {
        @media (max-width: 1023px) {
            width: 75px;
        }
        @media (min-width: 1024px) {
            height: 80px;
        }
    }
    .close {
        display: none;
    }
    .top20-header {
        opacity: 1 !important;
    }
    .hamburger {
        width: 31px;
        height: 31px;
        background-color: #fff;
        position: relative;
        border-radius: 50%;
        z-index: 9;
        transition: all 0.5s;
        span {
            width: 11px;
            height: 2px;
            background-color: #1a1a1a;
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            top: 11px;
            border-radius: 5px;
            transition: all 0.5s;
            &:nth-child(2) {
                top: 15px;
            }
            &:last-child {
                top: 19px;
            }
        }
    }
    .hamburger.active {
        background: #ebecef !important;
        span {
            background-color: #1a1a1a !important;
            &:first-child {
                transform: rotate(45deg);
                top: 15px;
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
            }
            &:last-child {
                opacity: 0;
            }
        }
    }
    .hamburger.hamburger-fixed {
        background: linear-gradient(to bottom, #ff5258, #e1171e);
        span {
            background-color: #fff;
        }
    }
    &.no-header {
        background: transparent;
        box-shadow: unset;
        justify-content: flex-end;
        .mirchi-logo,
        .hamburger {
            display: none;
        }
        .close {
            display: block
        }
    }
    &.bg-transparent {
        background: transparent !important;
        &.bg-black {
            background: #000 !important;
        }
    }
    &.mirchi-murga-bg {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05) !important;
        background-color: white !important;
    }
    .header-menu {
        @media (min-width: 1024px) {
            padding-bottom: 10px;
        }
        .header-text {
            @media (min-width: 1024px) {
                &.active {
                    color: #ed1c23;
                    padding-top: 3px;
                    border-bottom: 3px solid #ed1c23;
                }
            }
            @media (max-width: 1368px) and (min-width: 1113px) {
                padding: 0 10px;
                font-size: 14px;
            }
            @media (max-width: 1112px) and (min-width: 836px) {
                padding: 0 8px;
                font-size: 12px;
            }
            &:first-of-type {
                padding-left: 0;
            }
            // &:last-of-type {
            //     padding-right: 0;
            // }
            &.contact-us {
                @media (max-width: 1368px) {
                    padding-right: 0;
                }
            }
        }
    }
    .max-width {
        max-width: 1280px;
        margin: 0 auto;
        @media (min-width: 1025px) and (max-width: 1368px) {
            max-width: unset;
            padding: 0 60px;
        }
        @media (max-width: 1024px) {
            padding: 0 30px 0 10px;
        }
    }
    //.inquiry-modal {
    //    position: fixed;
    //    left: unset;
    //    width: auto;
    //    right: calc((100vw - 1280px) / 2);
    //    opacity: 0;
    //    visibility: hidden;
    //    z-index: -10;
    //    display: flex;
    //    align-items: center;
    //    justify-content: flex-end;
    //    background: #e1171e;
    //    @media (max-width: 1112px) {
    //        right: 20px;
    //    }
    //    &.active {
    //        width: 500px;
    //        opacity: 1;
    //        visibility: visible;
    //        z-index: 1000;
    //        top: 80px;
    //        left: unset;
    //        border-radius: 6px;
    //        &.event {
    //            width: 100%;
    //            top: 0;
    //            bottom: unset;
    //            background: rgba(#000, 0.8);
    //            left: 0;
    //            right: 0;
    //            height: 100%;
    //            align-items: flex-start;
    //            padding-top: 90px;
    //            .inner-content {
    //                margin: 0 auto;
    //            }
    //        }
    //    }
    //    .for-inquery {
    //        width: 100%;
    //        position: relative;
    //        .close-icon {
    //            display: block;
    //            cursor: pointer;
    //        }
    //    }
    //}
}
