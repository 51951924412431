@font-face {
    font-family: "radikal_regular";
    src: url("../fonts/radikal_regular.ttf") format("truetype"), url("../fonts/radikal_regular.otf") format("opentype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../fonts/CircularStd-Black.eot');
    src: url('../fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Black.woff2') format('woff2'), url('../fonts/CircularStd-Black.woff') format('woff'), url('../fonts/CircularStd-Black.ttf') format('truetype'), url('../fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../fonts/CircularStd-Medium.eot');
    src: url('../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Medium.woff2') format('woff2'), url('../fonts/CircularStd-Medium.woff') format('woff'), url('../fonts/CircularStd-Medium.ttf') format('truetype'), url('../fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../fonts/CircularStd-Book.eot');
    src: url('../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Book.woff2') format('woff2'), url('../fonts/CircularStd-Book.woff') format('woff'), url('../fonts/CircularStd-Book.ttf') format('truetype'), url('../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../fonts/CircularStd-Bold.eot');
    src: url('../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Bold.woff2') format('woff2'), url('../fonts/CircularStd-Bold.woff') format('woff'), url('../fonts/CircularStd-Bold.ttf') format('truetype'), url('../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}
