.pictures-page {
  display: block;
  justify-content: center;
  width: 80%;
  a {
    display: inline-block;
  }
  @media (max-width: 700px) {
    width: 100%;
  }
  .online-station-image {
    transform: rotate(-90deg);
    @media (max-width: 700px) {
      width: 100%;
    }
  }
  p {
    line-height: normal;
  }
}
.sub-content {
  width: 53%;
  float: left;
  padding: 0% 0% 0% 5%;
  @media (max-width: 928px) and (min-width: 200px) {
    width: 100%;
    padding: 0% 0% 0% 3%;
  }
}
.bingo-banner {
  padding: 0% 0% 0% 5%;
  width: 47%;
  @media (max-width: 928px) and (min-width: 200px) {
    width: 100%;
    padding: 2% 0% 0% 3%;
}
}
