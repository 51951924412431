@import "~video-react/styles/scss/video-react";
.component-video-card {
    // .video-title {
    //     display: -webkit-box;
    //     -webkit-line-clamp: 2;
    //     -webkit-box-orient: vertical;
    // }
    @media (max-width: 835px) and (min-width: 576px) {
        width: 40%;
    }
    &.player {
        &.audio-player {
            position: relative;
            &.player-container {
                box-shadow: unset;
                background: #fff;
                border-radius: 10px;
                padding: 5px;
                // width: 100%;
                // margin: 0 auto;
                // @media (min-width: 1025px) {
                //     width: 25%;
                // }
                >div {
                    // border-bottom: solid 1px rgba(0, 0, 0, 0.08);
                    &.iframe-container {
                        border-bottom: 0;
                    }
                    // &:last-of-type {
                    //     border-bottom: 0;
                    // }
                }
            }
            &.player-container.video-player {
                padding: 0;
                background: transparent;
                // display: flex;
                overflow: auto;
                border-radius: 0;
                // @media (max-width: 767px) {
                //     width: calc(100% + 30px);
                //     margin-left: -15px;
                //     padding: 0 15px;
                // }
                .video-container {
                    position: relative;
                    display: inline-block;
                    &.top20 {
                        &:last-of-type {
                            padding-right: 0;
                        }
                    }
                    &:last-of-type {
                        padding-right: 10px;
                    }
                    &.iframe-container {
                        background-position: center;
                        background-size: cover;
                        background-repeat: no-repeat;
                        border-radius: 8px;
                        &.top20 {
                            background-size: cover;
                            margin-bottom: 0;
                            height: 100%;
                        }
                        &.rounded-none {
                            border-radius: 0;
                        }
                    }
                    span {
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        color: #fff;
                        font-size: 14px;
                    }
                    img.video-icon {
                        position: absolute;
                        height: 40px;
                        width: 40px;
                        top: calc(50% - 20px);
                        left: calc(50% - 20px);
                        z-index: 10;
                        cursor: pointer;
                    }
                    &.play {
                        display: flex;
                        align-items: center;
                        .react-player {
                            flex: 0 0 100%;
                            overflow: hidden;
                        }
                        span {
                            display: none;
                        }
                        img.play {
                            display: none;
                        }
                        img.pause {
                            display: block;
                        }
                        .react-player.video-react-fullscreen {
                            video {
                                height: 100% !important;
                                max-height: unset;
                                object-fit: contain
                            }
                        }
                    }
                    &.pause {
                        span {
                            display: none;
                        }
                        display: block;
                        img.play {
                            display: block;
                        }
                        img.pause {
                            display: none;
                        }
                    }
                }
                @media (min-width: 1025px) {
                    flex-wrap: wrap;
                    .video-container {
                        flex: 0 0 32%;
                        padding: 0;
                        height: 100%;
                        margin: 0 12px 12px 0;
                        &:nth-child(3n) {
                            margin-right: 0;
                        }
                    }
                }
                .react-player {
                    flex: 0 0 75%;
                    // margin-right: 10px;
                    //@media (min-width: 1025px) {
                    //    height: 100% !important;
                    //}
                    //@media (max-width: 1024px) {
                    //    height: 100%;
                    //}
                    //@media (max-width: 576px) {
                    //    height: 100%;
                    //}
                    &.video {
                        background-size: cover;
                        background-repeat: no-repeat;
                        padding-top: 0 !important;
                        height: 150px;
                        border-radius: 10px;
                        background: #000;
                        &.top20 {
                            height: 100%;
                            width: 100%;
                        }
                        @media (min-width: 1024px) {
                            height: 150px;
                        }
                        &.listing-page {
                            height: 180px;
                            @media (max-width: 835px) and (min-width: 767px) {
                                height: 350px;
                                width: 100%;
                                iframe,
                                video {
                                    height: 350px;
                                    width: 100%;
                                }
                            }
                        }
                        &.video-react-fullscreen {
                            height: 100% !important;
                            iframe,
                            video {
                                visibility: visible;
                                height: 100% !important;
                                width: 100%;
                                object-fit: contain;
                                overflow: hidden;
                                border-radius: 8px;
                            }
                        }
                        iframe,
                        video {
                            visibility: visible;
                            // height: 150px;
                            width: 100%;
                            object-fit: cover;
                            object-position: center;
                            overflow: hidden;
                            border-radius: 8px;
                            @media (max-width: 835px) and (min-width: 767px) {
                                &.listing-page {
                                    height: 350px;
                                    width: 100%;
                                }
                            }
                            @media (min-width: 1024px) {
                                height: 150px;
                            }
                        }
                        &.hidden {
                            iframe,
                            video {
                                visibility: hidden;
                            }
                        }
                        &.listing-page {
                            iframe,
                            video {
                                @media (min-width: 1024px) {
                                    height: 180px;
                                }
                            }
                        }
                        //&.video-react-paused {
                        //    .video-react-control-bar {
                        //        display: none;
                        //    }
                        //}
                        //&.video-react-playing {
                        //    .video-react-control-bar {
                        //        display: flex;
                        //    }
                        //}
                    }
                }
            }
        }
        .iframe-hidden {
            visibility: hidden;
            display: none;
        }
        &.player-component {
            background: transparent;
            border-radius: 4px;
            box-shadow: unset;
            color: #fff;
            //height: 40px;
            width: 100%;
            margin: 10px 0 5px;
            left: unset;
            bottom: unset;
            @media (min-width: 1025px) {
                margin-bottom: 0;
                padding-bottom: 8px;
                //border-bottom: solid 1px rgba(#000, 0.08);
                border-radius: 0;
            }
            // >div:last-child {
            //     margin-bottom: 0;
            //     border-bottom: 0;
            //     padding-bottom: 0
            // }
            // &:last-of-type {
            //     margin-bottom: 0;
            //     border-bottom: 0;
            //     padding-bottom: 0
            // }
        }
        video {
            object-fit: contain;
        }
    }
    audio,
    video {
        &:focus {
            outline: none;
        }
    }
    .video-react-poster {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .video-react .video-react-big-play-button {
        font-size: 2em;
        line-height: 1.5em;
        height: 1.5em;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        border: 0;
        width: 1.5em;
        background: #fff;
        border-radius: 50%;
        display: none;
        &:before {
            color: #ce2f31;
        }
    }
    .video-react-current-time.video-react-time-control.video-react-control,
    .video-react-time-control.video-react-time-divider,
    .video-react-duration.video-react-time-control.video-react-control,
    .video-react-control-bar.video-react-control-bar-auto-hide {
        display: none;
    }
    .video-react.video-react-control-bar {
        border-radius: 0 0 10px 10px;
    }
    .video-react{
        overflow: hidden;
    }
    .d-none{
        display: none;
    }
    .tum-display{
        // position: absolute;
        z-index: 1;
    }
}
