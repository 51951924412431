.component-contact {
    .labelData{
        width: 25%;
        @media (max-width: 836px) {
            width: 100%
        }
    }
    @media (min-width: 1113px) {
        max-width: 1280px;
        margin: 0 auto;
        // a {
        //     width: calc(25% - 15px);
        //     margin-right: 20px;
        //     &:nth-child(4n) {
        //         margin-right: 0;
        //     }
        // }
    }
    @media (min-width: 1113px) and (max-width: 1280px) {
        // a {
        //     width: calc(33% - 20px);
        //     margin-right: 20px;
        //     &:nth-child(3n) {
        //         margin-right: 0;
        //         width: 33%;
        //     }
        // }
    }
    @media (max-width: 1112px) and (min-width: 836px) {
        max-width: unset;
        padding: 40px 30px;
        margin: 0 auto;
        // a {
        //     width: calc(33% - 15px);
        //     margin-right: 20px;
        //     &:nth-child(3n) {
        //         margin-right: 0;
        //     }
        // }
    }
    @media (min-width: 768px) and (max-width: 835px) {
        display: flex;
        flex-wrap: wrap;
        // a {
        //     width: calc(50% - 20px);
        //     margin-right: 20px;
        //     &:nth-child(2n) {
        //         margin-right: 0;
        //     }
        //     img.image-card {
        //         height: 250px;
        //     }
        // }
    }
    @media (min-width: 1025px) and (max-width: 1368px) {
        max-width: unset;
        padding: 40px 60px 0 75px;
    }
    button{
        display: inline-block;
        background-color: transparent;
        color: #191919;
        border-radius: 4px;
        padding: 0.625rem 0.9375rem;
        font-size: 16px;
        font-size: 1rem;
        line-height: 1.5;
        text-transform: uppercase;
        text-align: center;
        border: 1px solid #969a9f;
        text-decoration: none;
        font-weight: bold;
    }
    form{
        label{
            width: 25%;
            // display: inline-block;
            float: left;
        }
    }
    input, select{
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        padding: 4px 8px;
        display: inline-block;
        width: 100%;
        max-width: 450px;
        height: 30px;
        box-sizing: border-box;
        color: #555;
        line-height: 20px;
    }
    textarea{
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.8);
        border-radius: 4px;
        padding: 4px 8px;
        display: inline-block;
        width: 100%;
        max-width: 450px;
        height: 100px;
        box-sizing: border-box;
        color: #555;
        line-height: 20px;
    }
    .d-block{
        display: block;
    }
    h1{
        font-family: CircularStd, serif;
        font-weight: 900;
        color: #de393b;
        margin-bottom: 10px;
    }
}

.component-contact-us {
    overflow: hidden;
    @media (min-width: 1024px) {
        background: #fff;
    }
    .margin-1200 {
        @media (min-width: 1024px) {
            max-width: 1280px;
            margin: 0 auto;
            &.inner-container {
                width: 1280px;
                &.flex.flex-row {
                    flex-direction: row !important;
                }
                &.justify-center {
                    justify-content: center;
                }
            }
        }
        @media (min-width: 1025px) and (max-width: 1368px) {
            max-width: unset;
            padding: 0 60px;
        }
    }
    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .flex-row {
        display: flex;
        justify-content: space-between;
    }
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #f4f4f4;
    .top-container {
        .about {
            background-color: #ce2f31;
            background-size: cover;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #ce2f31;
        padding: 30px 50px 50px;
        height: 100%;
        position: relative;
        .text-hero {
            text-align: center;
            color: white;
            font-size: 21.1px;
            font-weight: 500;
            line-height: 1.36;
            letter-spacing: -0.5px;
            margin-bottom: 10px;
        }
        .text-hero-bottom {
            font-size: 48px;
            font-weight: 900;
            font-style: italic;
            line-height: 0.94;
            letter-spacing: -2.3px;
            text-align: center;
            color: #ffffff;
        }
        .mirchi-img {
            width: 263px;
            height: 250px;
            object-fit: contain;
            margin-left: 16px;
        }
        .menubar {
            background: #fff;
        }
        .bg {
            position: fixed;
        }
        @media (min-width: 1024px) {
            height: auto;
            padding: 50px 50px 60px;
            .top-header-contact-us {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;
                height: 250px;
                flex-direction: row-reverse;
                @media (min-width: 1024px) {
                    max-width: 1280px;
                }
                @media (min-width: 1025px) and (max-width: 1368px) {
                    max-width: unset;
                    padding: 0 20px;
                }
                .welcom-txt {
                    margin: 0;
                    max-height: unset;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    background: unset;
                    height: 100%;
                    .logo.desktop-img {
                        margin-left: 0;
                    }
                    img.desktop-img {
                        max-width: 120px;
                    }
                    div.text-hero {
                        margin-top: 0;
                        width: auto;
                        max-width: unset;
                        font-size: 24px;
                        padding: 0;
                        text-align: left;
                        margin-left: 0;
                    }
                    div.text-hero-bottom {
                        text-align: left;
                    }
                }
            }
            img.mirchi-img {
                width: 350px;
                height: auto;
            }
        }
    }
}
.component-contact-us {
    @media (min-width: 1024px) {
        background: #fff;
    }
    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .flex-row {
        display: flex;
        justify-content: space-between;
    }
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #f4f4f4;
    .top-container {
        .about {
            background-image: url('../../static/images/bg1.png');
            background-repeat: no-repeat;
        }
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #CE2F31;
        padding: 52px;
        height: 100%;
        position: relative;
        .menu-header {
            margin-top: 6px;
            margin-right: 34px;
        }
        .text-hero {
            text-align: center;
            color: white;
        }
        .mirchi-img {
            width: 263px;
            height: 250px;
            object-fit: contain;
        }
        .menubar {
            margin-bottom: 20px;
        }
        .bg {
            position: fixed;
        }
        @media (min-width: 1024px) {
            height: auto;
            border-radius: 10px 10px 0 0;
            .top-header-contact-us {
                display: flex;
                align-items: unset;
                justify-content: space-between;
                width: 100%;
                height: 290px;
                .welcom-txt {
                    margin: 0;
                    max-height: unset;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding-bottom: 40px;
                    background: unset;
                    img.desktop-img {
                        max-width: 120px;
                    }
                    h1 {
                        margin-top: 0;
                        width: 100%;
                        max-width: unset;
                        font-size: 40px;
                        padding: 0;
                        text-align: left;
                    }
                }
            }
            img.mirchi-img {
                width: 350px;
                height: 240px;
            }
        }
    }
}