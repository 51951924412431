.border-10 {
    border-radius: 10px;
}

.img-desktop {
    margin: 0 auto;
    margin-bottom: 24px;
}

.top20Card {
    margin-left: unset;
    margin-bottom: unset;
    margin-right: 20px;
    @media (max-width: 1024px) and (min-width: 768px) {
        height: 40px !important;
        width: 40px !important;
        margin-right: 30px;
    }
}

.image-card {
    min-width: 130px;
    min-height: 130px;
    &.stationCard {
        min-width: 240px;
        height: 360px;
        object-fit: cover;
        object-position: top;
        @media (max-width: 1024px) {
            min-width: 200px;
            min-height: 200px;
        }
    }
    &.home-station-card {
        height: auto;
        width: 100%;
    }
    &.magicInfo {
        min-width: 80px;
        min-height: 80px;
    }
    @media (min-width: 1024px) {
        min-width: 150px;
        min-height: 150px;
        &.stationCard {
            min-width: 200px;
            // min-height: 200px;
        }
        &.stationDetailBanner {
            max-height: 480px;
        }
        &.rjImgDesktop {
            min-width: 200px;
        }
        &.review {
            min-height: 300px;
        }
    }
    @media (max-width: 835px) and (min-width: 768px) {
        &.stationDetailBanner {
            max-height: 400px;
        }
    }
    background-color: #d3d3d3;
}

img.margin-0-img.mr-0 {
    margin-right: 0;
    margin-bottom: 0;
}
.ie-img{
    font-family: 'object-fit: cover;';
}
.ie-image-container{
    width: 200px;
    height: 355px;
    overflow: hidden;
    border-radius: 10px;
}
.ie-img{
    width: auto !important;
    position: absolute;
    height: 100% !important;
    left: 50%;
    transform: translateX(-50%);
    max-width: initial !important;
    border-radius: 8px !important;
    }