.blog-wrapper {
  width: 1200px;
  padding-top: 0px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 836px) {
    width: 70%;
    margin: 0 10%;
  }
}

.main {
  .w-100 {
    width: 100%;
  }
  .w-120 {
    width: 120%;
  }
  .w-70 {
    width: 70%;
  }
  .w-50 {
    width: 50%;
  }
}
.singleBlog {
  width: 100%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .blogImg {
    width: 80%;
  }
  .blog-about {
    width: 70%;
    height: 30%;
    padding: 0 3%;
  }

  @media (max-width: 600px) and (min-width: 200px) {
    flex-direction: column;
    .blog-about {
      padding: 0;
      width: 100%;
    }
    .blogImg {
      width: 100%;
    }
  }
}
.miniBlog {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  .blogImg {
    width: 100%;
    margin-bottom: 20px;  
  }
  .blog-about {
    padding: 0 3%;
    // float: left;
  }
  @media (max-width: 600px) and (min-width: 200px) {
    flex-direction: column;
    .blogImg {
      width: 250px;
      height: 200px;
      margin-bottom: 0px;
    }
  }
}
.headerdes {
  p {
    line-height: normal;
  }
}
.date {
  font-size: small;
  color: grey;
}
.grey {
  color: grey;
  padding: 2% 0;
  line-height: normal;
}
.detialDesc,
.category,
.title {
  padding: 2% 0;
  font-size: medium;
  font-weight: 900;
  line-height: normal;
}
.title {
  font-size: large;
}
.blogBannerImg {
  width: 100%;
  height: 60%;
}
.blogDescription {
  padding: 2% 0;
}
.btnMore {
  width: 30%;
  height: 40px;
  color: aliceblue;
  background-color: red;
  border-radius: 6px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 6% 0 8% 280px;
  @media (max-width: 836px) and (min-width: 200px) {
    width: 60%;
    margin: 5% 0 12% 20%;
  height: 35px;

  }
}
.btnMore-mini {
  width: 30%;
  height: 30px;
  font-size: 13px;

  color: aliceblue;
  background-color: red;
  border-radius: 6px;
  margin: 8% 0 8% 110px;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: 836px) and (min-width: 200px) {
    width: 60%;
    margin: 10% 0 10% 20%;
  height: 25px;

  }
}
.subContent {
  padding: 2% 0;
}
.blogFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.topContent {
  // width: 99vw;
  background: rgba(0, 0, 0, 0.644);
  margin-bottom: 6%;
  // margin-top: -3%;
  @media (max-width: 836px) {
    margin-top: 10%;
  }
}

.blog-container {
  @media (max-width: 1024px) {
    max-width: calc(100%);
  }
  @media (min-width: 1025px) {
    max-width: calc(100% - (100vw - 1280px) / 2);
    // padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) and (max-width: 1368px) {
    max-width: calc(100% - (100vw - 1280px) / 2);
    // padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (max-width: 835px) {
    max-width: unset;
    // padding-right: 0;
  }
}
.columm{
  padding-top: 5%;
}
.singleTile {
  position: relative;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .blog-content {
    position: absolute;
    padding: 3%;
    color: #fff;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    font-weight: 700;
    z-index: 2;
    // height: 30%;
    // width: 30%;
  }

  .overlay {
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
    font-weight: 700;
    z-index: 2;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
.bottom-container {
  @media (max-width: 1024px) {
    max-width: calc(100%);
    // padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) {
    max-width: calc(100% - (100vw - 1280px) / 2);
    // padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (min-width: 1025px) and (max-width: 1368px) {
    max-width: calc(100% - (100vw - 1280px) / 2);
    // padding-right: 30px;
    // padding-left: calc((100vw - 1280px) / 2);
  }
  @media (max-width: 835px) {
    max-width: unset;
    // padding-right: 0;
  }
}
.bottom-outer-container {
  //max-width: 1280px;
  padding-left: calc((100vw - 1280px) / 2 + 15px);
  @media (min-width: 1025px) and (max-width: 1368px) {
    padding-left: 55px;
  }
}

.col-0 {
  img {
    width: 100%;
    height: 30.7vw;
  }
  @media (min-width: 1025px) {
    width: 50%;
    height: 55%;
    img {
      margin-top: -2.2%;
    }
  }
  width: 100%;
  height: 100%;
  // margin-top: 4%;

  @media (max-width: 836px) and(min-width:200px) {
    // margin-top: 0;
    padding-top: 0%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.col-1 {
  @media (min-width: 1025px) {
    width: 50%;
  }
  @media (max-width: 1025px) {
    padding: 3%;
  }
  width: 100%;
  height: 100%;
  padding-left: 3%;
  margin-top: 2px;
  .grid-container {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 50% 50%;
    grid-gap: 5%;
    padding: 0;
    justify-content: center;
    height: 100%;
    @media (max-width: 836px) {
      grid-template-columns: 51% 51%;
    }
  }
}
.row {
  display: flex;
  flex-direction: row;
  @media (max-width: 1025px) {
    flex-direction: column;
  }
}
.pr{
  padding-right: 30px;
  @media (max-width:900px) {
    padding-right: 0px;
  }
}
.blog {
  @media (max-width:900px) {
    margin-top: 20%;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 2% 0;
    font-weight: bolder;
  }

  h1,
  .titleHeading {
    font-family: "Roboto Condensed", sans-serif;
    font-size: x-large;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 10px;
    letter-spacing: -0.5px;
    line-height: 1em;
  }
}

.blogDescription {
  iframe {
    max-width: 100%;
    min-height: 100px;
    vertical-align: top;
  }
  .rating-number {
    margin-top: 35px;
    margin-bottom: 25px;
    color: #9d9d9d !important;
    letter-spacing: 1px;
    letter-spacing: -5px;
    font-weight: 700;
    font-size: 50px;
    font-family: "Roboto Condensed", sans-serif !important;
    .small {
      padding-left: 2px;
      letter-spacing: -1px;
      font-weight: 300;
      font-size: 18px;
    }
  }
  .post_featured {
    img {
      max-height: 80vh;
    }
  }
  .video-responsive {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  h4 {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 1.188em;
    font-weight: 700;
    font-style: normal;
    line-height: 1.05em;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -0.8px;
    margin-top: 1.9em;
    margin-bottom: 1.2em;
  }
  img {
    aspect-ratio: auto 1024 / 512;
    padding: 10px 0;
  }
  p {
    margin-top: 0em;
    margin-bottom: 1.9em;
  }
}

.author {
  text-decoration: none;
  text-transform: uppercase;
  color: grey;
  line-height: normal;
  padding: 10px 0;
}
