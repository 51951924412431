.cardoverlay {
  background: rgba(0, 0, 0, 0.5);
  bottom: 20px;
  left: 0;
  height: 40%;
}

.title3 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 0.7vw;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
  @media (max-width: 1002px) {
    font-size: 2.2vw;
  }
  @media (max-width: 900px) {
    font-weight: 700;
  }
}
.title3-big {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
  font-size: 1.5vw;
  @media (max-width: 1002px) {
    font-size: 3vw;
  }
}
.category3 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 10px;
  font-weight: 800;
  font-style: normal;
  line-height: 1.05em;
  text-decoration: none;
  text-transform: uppercase;
  // letter-spacing: -1.15px;
  color: #f8b350;
  padding-bottom: 10px;
  @media (max-width: 900px) {
    // font-weight: 700;
  }
 
}
.date3 {
  font-size: 9px;
  color: #d2d2d2;
  padding-top: 8px;
  text-transform: uppercase;

  @media (max-width: 900px) {
    font-size: 7px;
  }
}