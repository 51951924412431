.singleBlog1 {
  width: 100%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: row;
  .blogImg1 {
    // width: 50%;
    width: 97px;
    height: 81px;
    object-fit: cover;
    img {
      width: 97px;
      height: 72px;
      object-fit:cover;
    }
  }
  .blog-about1 {
    width: 70%;
    height: 30%;
    padding: 3% ;
  }

  @media (max-width: 900px) and (min-width: 200px) {
      .title1 {
      padding: 0 0 0 4%;
    }
    // .blogImg1 {
    //   height: 100% !important;
    //   width: 100% !important;
    //   object-fit: cover;
    // }
    .blog-about1{
    padding: 0 3% ;

    }
  }
}

.grey1 {
  color: grey;
  padding: 2% 0;
  line-height: normal;
}
.category1 {
  font-size: medium;
  padding: 2% 0;
  font-weight: 900;
  line-height: normal;
}
.title1 {
  font-family: "Roboto Condensed", sans-serif;
  font-size: small;
  font-weight: bold;
  font-style: normal;
  line-height: 1.2em;
  text-decoration: none;
  // text-transform: uppercase;
  letter-spacing: -0.5px;
  color: black;
}
