.component-audio-card {
  box-shadow: 0 6px 10px 0 rgba(29, 40, 55, 0.07);

  &.rj-detail-page {
    width: calc(33% - 8px);
  }

  .min-width {
    min-width: 40px;

    @media (min-width: 1024px) {
      min-width: 32px;
      // min-height: 60px;
    }
  }

}

.no-margin-left {
  margin-left: 0 !important;
  min-height: 60px;
}

.audio-card-border {
  border: solid 1px #ebecef;
}
