.cards-header {
  @media (min-width: 1024px) {
    // padding: 12px 60px;
    // max-width: 65%;
  }

  .live-container {
    box-shadow: 0 3px 7px 0 rgba(232, 36, 43, 0.3);
    background-image: linear-gradient(to bottom, #ff5258, #e1171e);
    padding: 10px;
  }

  .dark-red-ring {
    left: -25%;
    top: -75%;
    width: 78px;
    height: 78px;
    opacity: 0.03;
    box-shadow: 0 3px 7px 0 rgba(232, 36, 43, 0.3);
    background-image: linear-gradient(to bottom, #ff5258, #e1171e);
  }

  .light-red-ring {
    left: -70%;
    top: -145%;
    width: 124px;
    height: 124px;
    opacity: 0.03;
    box-shadow: 0 3px 7px 0 rgba(232, 36, 43, 0.3);
    background-image: linear-gradient(to bottom, #ff5258, #e1171e);
  }
  .subheading {
    @media (max-width: 360px) {
      font-size: 15px;
    }
  }
}
