.component-footer {
    .inner-container {
        max-width: 1280px;
        margin: 0 auto;
        @media (min-width: 1025px) and (max-width: 1368px) {
            max-width: unset;
            padding: 0 40px;
        }
        @media (max-width: 1280px) {
            width: 100%;
            max-width: unset;
        }
    }
    .footer-logo{
        @media (max-width: 1025px) {
            width: 40%;
        }
    }
    background: url("../../static/images/footer-bg.svg"),
    #000;
    background-repeat: no-repeat;
    background-size: contain;
    .footer-text {
        line-height: 1.83
    }
    @media (min-width: 1024px) {
        background: url("../../static/images/footer-bg-desktop.svg"), #000;
    }
}