.stations-holder {
    .home-stations {
        @media (min-width: 1024px) {
            width: 190px;
            height: auto;
            margin-right: unset;
        }
    }
    img.play-icon {
        top: calc(50% - 20px);
    }
    .station-position {
        bottom: 28px !important;
        left: 28px !important;
        &.station-details-banner {
            bottom: 20px !important;
            left: 20px !important;
            @media (min-width: 1280px) {
                left: calc((100vw - 1280px) / 2 + 35px) !important;
            }
            @media (min-width: 1025px) and (max-width: 1368px) {
                left: 70px !important;
            }
            @media (max-width: 1024px) {
                left: 32px !important;
            }
        }
    }
    .station-details {
        bottom: 22px;
        left: 22px;
        max-width: 90%;
        @media (max-width: 1112px) and (min-width: 835px) {
            bottom: 20px !important;
            left: 20px !important;
            right: 5px !important;
            .home-station-name {
                font-size: 22px !important;
            }
        }
        @media (min-width: 1113px) {
            bottom: 20px;
            left: 20px;
            .home-station-name {
                font-size: 22px !important;
            }
        }
        &.bottom-26 {
            bottom: 26px;
        }
        .frequency {
            border-radius: 12px;
            padding: 4px 8px;
            width: fit-content;
            margin-right: 10px;
            &.stationBanner.multiple  {
                margin-bottom: 0;
            }
            &.multiple {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.station-desktop-card {
        padding: 10px;
        margin-bottom: 0;
        margin-right: 0;
        width: unset;
    }
}
