.component-review-card {
    .img-container {
        .overlay {
            background: rgba(0, 0, 0, 0.5)
        }
        .play-icon {
            top: calc(50% - 20px);
        }
        &.ie-img-container{
            width: 190px;
            height: 355px;
            overflow: hidden;
            border-radius: 10px;
            .overlay, .play-icon{
                z-index: 1;
            }
        }
    }
    .play-icon {
        top: 93px;
    }
    @media (min-width: 1025px) {
        width: calc(100% - 12px);
    }
    &.listing-screen {
        @media screen and (max-width: 835px) and (min-width: 768px) {
            width: calc(33% - 12px) !important;
        }
        @media screen and (max-width: 767px) {
            width: calc(33% - 12px) !important;
        }
        @media screen and (max-width: 576px) {
            width: calc(50% - 12px) !important;
        }
        @media (min-width: 1113px) {
            width: calc(20% - 16px);
            margin-right: 20px;
            &:nth-child(5n) {
                margin-right: 0;
            }
        }
        @media (min-width: 1113px) and (max-width: 1280px) {
            width: calc(25% - 15px);
            margin-right: 20px;
            &:nth-child(4n) {
                margin-right: 0;
            }
        } @media (max-width: 1112px) and (min-width: 836px) {
            width: calc(25% - 15px);
            margin-right: 20px;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        .mirchi-container {
            max-width: 80px;
        }
    }
    @media (min-width: 1025px) {
        img.mirchi {
            max-width: 20px;
            height: auto;
        }
    }
    .movie-name{
        white-space: normal;
    }

}
.reviews-mobile{
    width: 183px;
}
